<template>
  <block
    component-name="block-text-couple"
    body-class="flex flex-col gap-3xl items-stretch"
  >
    <header
      class="grid grid-cols-1 tablet:grid-cols-12 gap-x-ui gap-y-2xl w-full text-skin-base"
    >
      <div class="col-span-full tablet:col-start-1 tablet:col-end-6 w-full">
        <wrapper-split-text
          reveal="chars"
          class="relative top-0 left-0 w-full"
          :class="{
            'tablet:sticky tablet:top-30 laptop:top-40': useSticky,
          }"
        >
          <h3
            ref="tpl_title"
            v-html="$attrs.data.body.title"
            class="w-full text-heading-3"
          ></h3>
        </wrapper-split-text>
      </div>

      <div
        class="col-span-full tablet:col-start-7 tablet:col-end-12 desktop:col-end-11 w-full"
      >
        <wrapper-reveal reveal="fade-from-right" class="w-full">
          <div
            ref="tpl_text"
            v-html="$attrs.data.body.text"
            class="w-full text-heading-6"
          ></div>
        </wrapper-reveal>
      </div>
    </header>

    <wrapper-reveal
      v-if="$attrs.data.body.button"
      class="w-full"
      reveal="fade-from-right"
    >
      <btn-pill
        size="big"
        :title="$attrs.data.body.button.title"
        :icon="$attrs.data.body.button.icon || undefined"
        :action="$attrs.data.body.button.action"
      />
    </wrapper-reveal>
  </block>
</template>

<script setup>
const { height: windowH } = useWindowSize();
const tpl_title = templateRef("tpl_title");
const tpl_text = templateRef("tpl_text");
const { height: titleH } = useElementSize(tpl_title);
const { height: textH } = useElementSize(tpl_text);

const useSticky = computed(() => {
  return textH.value > windowH.value * 0.7 && textH.value / 2.2 > titleH.value;
});
</script>
